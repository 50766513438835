<template>
  <div class="mt-10 px-4">
    <!-- background -->
    <div class="background-ball-big"></div>
    <div class="background-ball-small"></div>

    <div class="pt-4 title">
      <h3>Sign In</h3>
    </div>

    <template v-if="layouts.actives.login">
      <div class="f-s-16 mt-2">
        Please enter the one-time-passcode sent to your email account.
      </div>
      <v-form ref="form" @submit.prevent>
        <v-text-field
          v-model="dataSources.current.oneTimeCode"
          label="One-Time Passcode"
          :rules="dataSources.current.oneTimeCodeRule"
        ></v-text-field>
      </v-form>
      <a class="pull-right f-s-13">
        <span :style="dataSources.current.countdown > 0 ? 'color: grey' : ''" @click="onClickSentence">
          Didn't receive the email?
        </span>
        <span class="text-red" v-if="dataSources.current.countdown > 0"> ({{ dataSources.current.countdown }}s)</span>
      </a>

      <wap-btn class="mt-4 width-full" @click="clickContinue">
        Continue
      </wap-btn>
    </template>

    <template v-else>
      <wap-text-field
        label="Enter Email"
        v-model="dataSources.current.email"
        @change="layouts.actives.alert = false"
      >
      </wap-text-field>
      <wap-text-field
        label="Enter Password"
        :append-icon="layouts.actives.showPwd ? 'mdi-eye' : 'mdi-eye-off'"
        :type="layouts.actives.showPwd ? 'text' : 'password'"
        v-model="dataSources.current.password"
        @click:append="layouts.actives.showPwd = !layouts.actives.showPwd"
        @change="layouts.actives.alert = false"
      >
      </wap-text-field>

      <a class="pull-right f-s-13" @click="$router.push('/send-reset-link')">Forgot Password?</a>

      <wap-btn class="mt-4 width-full" :disabledControl="layouts.actives.alert" @click="clickSignIn"> Sign In </wap-btn>

      <div class="d-flex align-center my-8">
        <v-divider></v-divider>
        <span class="px-4">Or</span>
        <v-divider></v-divider>
      </div>

      <div class="text-center">
        Not registered yet? <a class="f-w-600" @click="$router.push('/send-set-link')">Create Account</a>
      </div>
    </template>

    <wap-bottom-sheet
      v-model="layouts.actives.resendSheet"
      :persistent="true"
      title="Didn't receive the email?"
      :notification-sheet="true"
      @confirm="layouts.actives.resendSheet=false"
    >
      <v-card width="100%" flat class="px-5 d-flex justify-center text-center">
        <v-card @click="clickResend" rounded class="px-4">
          <v-icon size="45" color="var(--theme_secondary)">refresh</v-icon>
          <p class="f-s-16 f-w-600">Resend Email</p>
        </v-card>
      </v-card>
    </wap-bottom-sheet>

  </div>
</template>

<script>
import WapTextField from '@/components/base/WapTextField'
import WapBtn from '../../components/base/WapBtn.vue'
import WapBottomSheet from '@/components/base/WapBottomSheet'
import { AccountApi } from '@/api'
import { Cache, DataType, Tool, Crypto } from '@/assets/js/util'

export default {
  components: { WapTextField, WapBtn, WapBottomSheet },
  data () {
    return {
      timer: null,
      assists: {
        tool: new Tool(),
        cache: new Cache()
      },
      dataSources: {
        current: {
          email: '',
          password: '',
          oneTimeCode: '',
          oneTimeCodeRule: [v => /^\d{6}$/.test(v) || 'One-Time Passcode is invalid.'],
          countdown: 60
        }
      },
      layouts: {
        actives: {
          login: false,
          resendSheet: false,
          showPwd: false,
          alert: false
        }
      }
    }
  },
  methods: {
    clickSignIn () {
      const { email, password } = this.dataSources.current

      if (
        !DataType.ValidationRules.EMAIL.test(email) ||
        this.assists.tool.isEmpty(email) ||
        this.assists.tool.isEmpty(password)
      ) {
        this.$store.commit('setPopupInformation', {
          message: 'Incorrect email or password.'
        })
        this.layouts.actives.alert = true
        return
      }

      AccountApi.signIn(
        {
          portfolioId: this.$store.getters.getPortfolioId,
          email,
          password: Crypto.md5(password)
        },
        (res) => {
          const { mfaStatus, token } = res

          if (mfaStatus === DataType.StatusEnum.ENABLE.value) {
            this.layouts.actives.login = true
            this.countDown()
          } else {
            this.assists.cache.setAccessToken(token)
            this.$router.push('/home')
          }
        },
        (err) => {
          this.layouts.actives.alert = true
          this.$store.commit('setPopupInformation', {
            message: err.message
          })
        }
      )
    },
    clickContinue () {
      this.$refs.form.validate()
      if (this.$refs.form.validate()) {
        const { email, oneTimeCode } = this.dataSources.current
        AccountApi.verifyLoginCode({
          email,
          portfolioId: this.$store.getters.getPortfolioId,
          code: oneTimeCode
        }, (res) => {
          this.assists.cache.setAccessToken(res)
          this.$router.push('/home')
        }, (err) => {
          this.$store.commit('setPopupInformation', {
            message: err.message
          })
        })
      }
    },
    onClickSentence () {
      const { countdown } = this.dataSources.current
      if (countdown > 0) {
        return
      }
      this.layouts.actives.resendSheet = true
    },
    clickResend () {
      AccountApi.resendVerificationCode({
        email: this.dataSources.current.email,
        portfolioId: this.$store.getters.getPortfolioId
      }, () => {
        this.layouts.actives.resendSheet = false
        this.countDown()
      }, (err) => {
        this.$store.commit('setPopupInformation', {
          message: err.message
        })
      })
    },
    countDown () {
      this.dataSources.current.countdown = 60
      this.timer = setInterval(() => {
        if (this.dataSources.current.countdown > 0) {
          this.dataSources.current.countdown--
        } else {
          clearInterval(this.timer)
        }
      }, 1000)
    }
  }
}
</script>

<style lang='sass' scoped>
.background-ball-big
  position: absolute
  top: -60vw
  left: -5vw
  width: 110vw
  height: 110vw
  background-color: var(--theme_primary_opacity_high)
  border-radius: 50%

.background-ball-small
  position: absolute
  top: -55vw
  right: -58vw
  width: 100vw
  height: 100vw
  background-color: var(--theme_primary_opacity_high)
  border-radius: 50%

.title
  height: 23vh
</style>
