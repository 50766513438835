<template>
  <div>
    <div class="main-content">
      <v-card flat color="transparent">
        <div class="f-s-24 f-w-900 px-0 py-2" v-html="layouts.data.title"></div>
        <div class="subtitle pa-0">
          {{ $route.path.includes('consent') ? layouts.data.LASecondTitle : layouts.data.secondTitle }}
        </div>
        <div>
          <v-card-text class="px-0">
            <v-card flat class="bg-accent">
              <v-card-title class="py-2">{{ layouts.settings.why[0].title }}</v-card-title>
              <v-card-text>
                <v-row dense justify="center">
                  <template v-for="(item, itemIndex) in layouts.settings.why[0].items">
                    <v-col cols="12" :key="itemIndex">
                      <div class="promotion-item">
                        <v-icon small color="#464C5B">mdi-menu-right</v-icon>
                        <span>{{ item }}</span>
                      </div>
                    </v-col>
                  </template>
                </v-row>
              </v-card-text>
            </v-card>
          </v-card-text>
        </div>
        <v-card-text class="pa-0 mx-0" v-if="!layouts.data.hasVerifyConsent && assist.tool.isNotEmpty(layouts.data.protocol) && !layouts.data.errorVerify">
          <v-flex v-for="(item, index) in layouts.data.protocol" class="d-flex align-center pb-1" :key="index">
            <div class="checkbox-input">
              <div class="checkbox-input-square" @click="onClickCheckbox(item)">
                <v-icon class="checkbox-input-status" size="12" color="#9CA0AB">
                  {{ item.check ? 'check' : '' }}
                </v-icon>
              </div>
            </div>
            <div>
              {{ item.labelPrefix }}
              <b class="color-primary consents-prominent" @click="onClickProtocol(item)">{{ item.templateLabel }}</b>
              <template v-if="assist.tool.isNotEmpty(item.extension) && item.extension.isOptional"> (optional)</template>
            </div>
          </v-flex>
          <v-alert
            dense
            text
            color="red"
            border="left"
            class="f-s-12"
            v-if="layouts.data.hasConsentDescription"
          >
            <div v-html="assist.tool.getConsentDescription(layouts.data.protocol)"></div>
          </v-alert>
        </v-card-text>
      </v-card>
    </div>

    <div class="footer">
      <wap-btn
        :disabled-control="layouts.data.errorVerify || !(layouts.data.hasVerifyConsent || layouts.data.requiredCheckbox)"
        :theme="layouts.data.errorVerify || !(layouts.data.hasVerifyConsent || layouts.data.requiredCheckbox) ? '' : 'primary'"
        :height="50"
        width="100%"
        :loading="layouts.settings.btnLoading"
        @click="onClickContinue"
      >Continue
      </wap-btn>
    </div>
    <wap-bottom-sheet
      v-model="layouts.dialog.protocol.display"
      :persistent="true"
      :title="layouts.dialog.protocol.json.templateTitle"
      :notification-sheet="true"
      :is-html="true"
      @confirm="layouts.dialog.protocol.display=false"
    >
      <v-card-text>
        <div v-html="layouts.dialog.protocol.json.templateContent"></div>
      </v-card-text>
    </wap-bottom-sheet>
  </div>
</template>

<script>
import { DataType, Tool } from '@/assets/js/util'
import { OnlineApi, PhoenixConfigApi } from '@/api'
import WapBottomSheet from '@/components/base/WapBottomSheet'
import WapBtn from '@/components/base/WapBtn'
import SYSTEM_ENUM from '@/settings/layout-setting/system/systemEnum'

const tool = new Tool()

export default {
  components: {
    WapBottomSheet,
    WapBtn
  },
  data: () => ({
    assist: {
      dataType: DataType,
      tool: tool
    },
    layouts: {
      data: {
        title: '',
        secondTitle: 'Let’s confirm some key information about your account to see if you are eligible for a loan!',
        LASecondTitle: 'Prior to finalizing your loan agreement, please carefully review and select the consents provided below.',
        protocol: [],
        hasVerifyConsent: false,
        errorVerify: false,
        hasConsentDescription: false,
        requiredCheckbox: false,
        portfolio: {
          iconUrl: ''
        }
      },
      dialog: {
        protocol: {
          display: false,
          json: {}
        }
      },
      settings: {
        why: SYSTEM_ENUM.WHY,
        disabledControl: false,
        btnLoading: false
      }
    },
    isWelcomePage: null
  }),
  watch: {
    'store.getters.getPortfolioJson': {
      handler: function (val) {
        if (tool.isNotEmpty(val)) {
          this.layouts.data.title = 'Welcome to ' + '<br>' + val.name
          this.layouts.settings.why[0].title = 'Why choose ' + val.name + '?'
          this.getProtocolTemplate(val)
        }
      }
    },
    'store.getters.getIsConsent': {
      handler (val) {
        if (tool.isNotEmpty(val) && this.$route.path.includes('consent')) {
          this.layouts.data.hasVerifyConsent = !val
        }
      }
    }
  },
  mounted () {
    const portfolio = this.store.getters.getPortfolioJson
    if (tool.isNotEmpty(portfolio)) {
      this.layouts.data.title = 'Welcome to ' + '<br>' + portfolio.name
      this.layouts.settings.why[0].title = 'Why choose ' + portfolio.name + '?'
      this.getProtocolTemplate(portfolio)
    }
    this.isWelcomePage = this.$route.path.includes('welcome')
    this.isWelcomePage ? this.getProtocol() : this.layouts.data.hasVerifyConsent = this.$store.getters.getIsConsent
  },
  methods: {
    onClickCheckbox (item) {
      item.check = !item.check
      this.$forceUpdate()
      this.layouts.data.hasConsentDescription = this.layouts.data.protocol.some(item => item.check)
      this.layouts.data.requiredCheckbox = this.layouts.data.protocol.every(item => {
        if (tool.isNotEmpty(item.extension) && item.extension.isOptional) {
          return true
        }
        return item.check
      })
    },
    onClickProtocol (protocol) {
      this.layouts.dialog.protocol.display = true
      this.layouts.dialog.protocol.json = protocol
    },
    getProtocolTemplate (portfolio) {
      const { portfolioId } = portfolio
      PhoenixConfigApi.getConsentTemplate({ portfolioId: portfolioId }, res => {
        if (tool.isNotEmpty(res) && tool.isNotEmpty(res?.templates)) {
          this.layouts.data.protocol = res.templates
          this.layouts.data.protocol.forEach(item => {
            item.check = false
          })
        }
      }, () => {
        this.layouts.data.errorVerify = true
      })
    },
    getProtocol () {
      OnlineApi.listConsentStatus((result) => {
        if (tool.isNotEmpty(result)) {
          this.layouts.data.hasVerifyConsent = result
        }
      }, () => {
        // 点击域名进来没有token,请求失败不让跳转
        this.layouts.data.errorVerify = true
      })
    },
    onClickContinue () {
      this.layouts.settings.btnLoading = true
      if (this.layouts.data.hasVerifyConsent) {
        this.directPage()
        return
      }
      if (this.layouts.data.requiredCheckbox) {
        this.$store.commit('setDocumentUrl', '')
        const consentTemplate = []
        this.layouts.data.protocol.forEach(item => {
          consentTemplate.push({
            consentId: item.consentTemplateId,
            status: item.check ? 1 : 0,
            versionId: item.versionId,
            isOptional: item.extension?.isOptional
          })
        })
        if (this.assist.tool.isEmpty(consentTemplate)) this.recordError()
        OnlineApi.batchSaveConsentStatus({
          consentDataList: consentTemplate,
          loanNo: this.$store.getters.getLoan.loanNo,
          resource: this.isWelcomePage ? null : this.$store.getters.getResource
        }, (res) => {
          if (!this.isWelcomePage) {
            this.layouts.settings.btnLoading = false
            this.$store.commit('setDocumentUrl', `${res}?embed=1`)
            this.$store.commit('setIsConsent', true)
            this.$router.push('/loan-agreement')
          }
          this.directPage()
        }, (err) => {
          this.recordError()
          this.layouts.settings.btnLoading = false
          this.assist.tool.setPopupInformation(err.message)
        })
      }
    },
    recordError () {
      const data = {
        protocol: this.layouts.data.protocol,
        checkBoxStatus: this.layouts.data.requiredCheckbox,
        hasVerifyConsent: this.layouts.data.hasVerifyConsent,
        errorVerify: this.layouts.data.errorVerify
      }
      const input = {
        loanNo: this.$store.getters.getLoan.loanNo,
        identity: 'WAP_CONSENT',
        businessData: JSON.stringify(data)
      }
      OnlineApi.recordFailureData(input)
    },
    directPage () {
      this.layouts.settings.btnLoading = false
      if (this.isWelcomePage) {
        // const type = this.$store.getters.getLoan.incompleteScriptType
        const route = this.$store.getters.getHasQualifiedPrincipal
          ? '/disbursement/choose-loan-amount'
          : '/guidance'
        this.$router.push(route)
      } else if (this.assist.tool.isNotEmpty(this.$store.getters.getDocumentUrl)) {
        this.$router.push('/loan-agreement')
      }
    }
  }
}
</script>

<style lang="sass" scoped>

.bg-accent
  background-color: var(--theme_third_opacity_high) !important
  color: #464C5B

.color-primary
  color: var(--theme_primary)

.subtitle
  color: #9CA0AB

.portfolio-icon
  height: 300px
  display: flex
  justify-content: center
  align-items: center

.checkbox-input
  padding: 0 12px

  &-square
    display: flex
    justify-content: center
    align-items: center
    width: 14px
    height: 14px
    border-radius: 2px
    background-color: #ffffff
    border: 1px solid #464C5B

  &-status
    font-size: 16px

.active
  border: 1px solid #9ca0ab !important

.templateLabel
  line-height: 40px
</style>
