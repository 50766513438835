<template>
  <v-app>
    <router-view v-if="isRouterAlive"></router-view>
    <v-snackbar
      v-model="signSnackbar.show"
      :color="signSnackbar.color"
      :timeout="20000"
      :top=true
    >
      {{ signSnackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="onClickSignOK"
        >
          OK
        </v-btn>
      </template>
    </v-snackbar>
    <popup-information></popup-information>
  </v-app>
</template>
<script>
import { InfrastructureApi, PhoenixConfigApi } from '@/api'
import { Tool, DataType, Sloth } from '@/assets/js/util'
import PopupInformation from '@/components/common/PopupInformation'

const tool = new Tool()
export default {
  components: { PopupInformation },
  data () {
    return {
      isRouterAlive: true,
      hasAccount: false,
      signSnackbar: {
        show: false,
        text: '',
        color: 'green',
        function: null,
        timeInt: function () {}
      }
    }
  },
  mounted () {
    this.$store.commit('setPreLoanFlag', null)
    if (typeof window !== 'undefined' && window._VMA === undefined) {
      window._VMA = this
    }
    this.getDatePatterns()
    this.generateHoliday()
    this.getPortfolio()
    this.$store.commit('setSessionId', tool.generalNanoid())
    // 实现域名切换
    window.addEventListener('resize', () => this.recalculateLayout())
  },
  methods: {
    recalculateLayout () {
      setTimeout(() => {
        this.judgementEnvironment()
      }, 0)
      document.getElementsByTagName('html')[0].style.removeProperty('overflow-y')
    },
    judgementEnvironment () {
      // 先判断域名地址和当前设备型号是否一致，一致才能继续解析
      const domain = document.domain
      if (domain.indexOf('localhost') !== -1) {
        return
      }
      if (tool.isEmpty(this.$store.getters.getPortfolioJson)) {
        return
      }
      if (this.$route.path.indexOf(tool.getSystemCode()) !== -1) {
        return
      }
      const key = tool.isPC() ? 'domainOfWeb' : 'domainOfWap'
      if (this.$store.getters.getPortfolioJson[key] === domain) {
        return
      }
      // 避免本地起的项目的ip也做切换
      if (domain !== this.$store.getters.getPortfolioJson.domainOfWeb && domain !== this.$store.getters.getPortfolioJson.domainOfWap) {
        return
      }

      const routePath = this.$route.path
      let redirectPath
      const redirectPaths = [
        '/returning/',
        '/application/',
        '/signature/',
        '/coupon/verification/',
        '/create-account/',
        '/reset-password/',
        '/complete-information/',
        '/pending-application/'
      ]
      if (redirectPaths.some(path => routePath.includes(path))) {
        redirectPath = routePath
      } else {
        redirectPath = tool.isNotEmpty(this.$store.getters.getRedirectUrlParameter)
          ? this.$store.getters.getRedirectUrlParameter
          : ''
      }
      window.location.href = `https://${this.$store.getters.getPortfolioJson[key]}#${redirectPath}`
    },
    getDatePatterns () {
      PhoenixConfigApi.getDateParameter((result) => {
        result.date = result.date.replace('dd', 'DD')
        this.$store.commit('setDateTimePatterns', result)
      })
    },
    generateHoliday () {
      InfrastructureApi.getHoliday((result) => {
        const holiday = []
        if (tool.isNotEmpty(result)) {
          result.map((item) => {
            holiday.push(item.holidayTimeStr)
          })
          this.$store.commit('setHoliday', holiday)
        }
      })
    },
    getPortfolio () {
      this.$store.commit('setPortfolioJson', {})
      if (document.domain.indexOf('localhost') === -1) {
        InfrastructureApi.getPortfolioByDomain(document.domain, (result) => {
          if (tool.isNotEmpty(result)) {
            this.$store.commit('setPortfolioJson', result)
            this.$store.commit('setPortfolioId', result.portfolioId)
            this.judgementEnvironment()
            this.displayFavicon()
          }
        })
      } else {
        const mock = 118
        PhoenixConfigApi.getPortfolio({ portfolioId: mock }, (res) => {
          // 存portfolioId，本地起项目时需要用到
          this.$store.commit('setPortfolioJson', { ...res, portfolioId: 118 })
          this.$store.commit('setPortfolioId', mock)
          this.displayFavicon()
        })
      }
    },
    displayFavicon () {
      const link = document.querySelector("link[rel*='icon']") || document.createElement('link')
      link.type = 'image/x-icon'
      link.rel = 'shortcut icon'
      link.href = this.$store.getters.getPortfolioJson.faviconUrl
      document.getElementsByTagName('head')[0].appendChild(link)
    },
    reload () {
      this.signTipData = this.$options.data().signTipData
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    },
    initWebSocket (account) {
      const $this = this
      $this.judgeEnvironment()
      let data = {}
      $this.sloth = new Sloth({
        url: $this.slothUrl,
        mode: 'ClientSide',
        wsid: account.openId
      })
      $this.sloth.init()
      $this.sloth.on('message', function (message) {
        switch (message.data.ticket) {
          case DataType.PhoenixActionEnum.SIGNATURE_CALLBACK.value: {
            data = message.data
            if (data.signStatus === DataType.SignStatusEnum.SIGNED.value) {
              $this.signCallBack()
            }
            break
          }
          case DataType.PhoenixActionEnum.IN_PROGRESS.value: {
            $this.$store.commit('setWebSocket', { ticket: DataType.PhoenixActionEnum.IN_PROGRESS.value })
            break
          }
          default:
            break
        }
      })
    },
    judgeEnvironment () {
      switch (process.env.NODE_ENV) {
        case 'develop':
          this.layouts.data.slothUrl = 'wsxchdolphin.sztnb.com'
          break
        case 'training':
          this.layouts.data.slothUrl = 'wsxchdolphin.sztnb.com'
          break
        case 'production':
          this.layouts.data.slothUrl = 'wsxch-dolphin.sztus.com'
          break
        case 'release':
          this.layouts.data.slothUrl = 'wsxch-dolphin.sztus.com'
          break
        case 'verification':
          this.layouts.data.slothUrl = 'wsxch-dolphin.sztup.com'
          break
        default:
          this.layouts.data.slothUrl = 'wsxchdolphin.sztnb.com'
          break
      }
    },
    signCallBack () {
      const displayText = 'All set. You will be redirected to the home page after ? seconds.'
      let callbackTime = 5
      this.signSnackbar.text = displayText.replace('?', 5)
      this.signSnackbar.color = 'green'
      this.signSnackbar.show = true
      this.signSnackbar.path = '/home'
      const $this = this
      this.signSnackbar.timeInt = setInterval(function () {
        callbackTime--
        if (callbackTime <= 0) {
          $this.signSnackbar.show = false
          clearInterval($this.signSnackbar.timeInt)
          $this.$router.push($this.signSnackbar.path)
        }
        $this.signSnackbar.text = displayText.replace('?', callbackTime)
      }, 1000)
    },
    onClickSignOK () {
      this.signSnackbar.show = false
      clearInterval(this.signSnackbar.timeInt)
      this.$router.push(this.signSnackbar.path)
    }
  },
  provide () {
    return {
      reload: this.reload
    }
  },
  created () {
  },
  watch: {
    '$route' (val) {
      // this.recalculateLayout()
      this.$vuetify.goTo(0)
    },
    'store.getters.getPortfolioId' () {
      this.getPortfolio()
    },
    'store.getters.getCollectionPortfolioId' () {
      this.getPortfolio()
    },
    // 监听事件，变化主题颜色
    'store.getters.getPortfolioJson': {
      handler (val) {
        if (tool.isNotEmpty(val)) {
          this.$vuetify.theme.themes.light.primary = val.primaryColor
          this.$vuetify.theme.themes.dark.primary = val.primaryColor
          this.$vuetify.theme.themes.light.secondary = val.secondaryColor
          this.$vuetify.theme.themes.dark.secondary = val.secondaryColor
          window.document.body.style.setProperty('--theme_primary', val.primaryColor)
          window.document.body.style.setProperty('--theme_primary_opacity_low', `rgba(${parseInt(val.primaryColor.slice(1, 3), 16)}, ${parseInt(val.primaryColor.slice(3, 5), 16)}, ${parseInt(val.primaryColor.slice(5, 7), 16)}, 0.5)`)
          window.document.body.style.setProperty('--theme_primary_opacity_high', `rgba(${parseInt(val.primaryColor.slice(1, 3), 16)}, ${parseInt(val.primaryColor.slice(3, 5), 16)}, ${parseInt(val.primaryColor.slice(5, 7), 16)}, 0.1)`)
          window.document.body.style.setProperty('--theme_secondary', val.secondaryColor)
          window.document.body.style.setProperty('--theme_secondary_opacity_low', `rgba(${parseInt(val.secondaryColor.slice(1, 3), 16)}, ${parseInt(val.secondaryColor.slice(3, 5), 16)}, ${parseInt(val.secondaryColor.slice(5, 7), 16)}, 0.5)`)
          window.document.body.style.setProperty('--theme_secondary_opacity_high', `rgba(${parseInt(val.secondaryColor.slice(1, 3), 16)}, ${parseInt(val.secondaryColor.slice(3, 5), 16)}, ${parseInt(val.secondaryColor.slice(5, 7), 16)}, 0.1)`)
          window.document.body.style.setProperty('--theme_third', val.thirdColor)
          window.document.body.style.setProperty('--theme_third_opacity_low', `rgba(${parseInt(val.thirdColor.slice(1, 3), 16)}, ${parseInt(val.thirdColor.slice(3, 5), 16)}, ${parseInt(val.thirdColor.slice(5, 7), 16)}, 0.5)`)
          window.document.body.style.setProperty('--theme_third_opacity_high', `rgba(${parseInt(val.thirdColor.slice(1, 3), 16)}, ${parseInt(val.thirdColor.slice(3, 5), 16)}, ${parseInt(val.thirdColor.slice(5, 7), 16)}, 0.2)`)
        }
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="sass">
.van-picker-column__item--selected
  color: var(--theme_primary)!important // Vant 选中项的样式

.v-card__title
  font-size: inherit
  font-weight: 700 !important
  word-break: break-word

.v-card__text
  font-size: inherit
  color: inherit !important

.v-card__actions
  justify-content: center
  margin: 0

.v-card__actions.d-block .v-btn
  margin: 0 auto

.v-card__actions.d-block .v-btn + .v-btn
  margin-left: auto !important
  margin-right: auto !important
  margin-top: 10px !important

// variable definitions
$fontFamily: "PT Sans", sans-serif !important

// overwrite vuetify style for testing
.v-application
  font-family: $fontFamily
  & .headline, .title, .subtitle-1, .subtitle-2, .caption, .overline,
  .body-1, .body-2, .display-1, .display-2, .display-3, .display-4,
  .text-h1, .text-h2, .text-h3, .text-h4, .text-h5, .text-h6, .text-subtitle-1, .text-subtitle-2,
  .text-body-1, .text-body-2, .text-button, .text-caption, .text-overline
    font-family: $fontFamily

@media (min-width: 600px)
  .v-application
    & .text-sm-h1, .text-sm-h2, .text-sm-h3, .text-sm-h4, .text-sm-h5, .text-sm-h6,
    .text-sm-subtitle-1, .text-sm-subtitle-2, .text-sm-body-1, .text-sm-body-2,
    .text-sm-button, .text-sm-caption, .text-sm-overline
      font-family: $fontFamily

@media (min-width: 960px)
  .v-application
    & .text-md-h1, .text-md-h2, .text-md-h3, .text-md-h4, .text-md-h5, .text-md-h6,
    .text-md-subtitle-1, .text-md-subtitle-2, .text-md-body-1, .text-md-body-2,
    .text-md-button, .text-md-caption, .text-md-overline
      font-family: $fontFamily

@media (min-width: 1264px)
  .v-application
    & .text-lg-h1, .text-lg-h2, .text-lg-h3, .text-lg-h4, .text-lg-h5, .text-lg-h6,
    .text-lg-subtitle-1, .text-lg-subtitle-2, .text-lg-body-1, .text-lg-body-2,
    .text-lg-button, .text-lg-caption, .text-lg-overline
      font-family: $fontFamily

@media (min-width: 1904px)
  .v-application
    & .text-xl-h1, .text-xl-h2, .text-xl-h3, .text-xl-h4, .text-xl-h5, .text-xl-h6,
    .text-xl-subtitle-1, .text-xl-subtitle-2, .text-xl-body-1, .text-xl-body-2,
    .text-xl-button, .text-xl-caption, .text-xl-overline
      font-family: $fontFamily
</style>
