<template>
  <div>
    <v-card flat class="review" color="transparent">
      <div class="review-title">Calculating your qualified amount.</div>
      <div class="review-title">It might take a while, please don’t refresh or leave the page!</div>
      <v-card-text class="review-content">
        <v-progress-circular
          :size="120"
          :width="8"
          color="var(--theme_secondary)"
          indeterminate
        >
          <v-avatar color="transparent" size="100">
            <img v-if="layouts.data.portfolio.iconUrl" class="icon-url" :src="layouts.data.portfolio.iconUrl">
          </v-avatar>
        </v-progress-circular>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { OnlineApi } from '@/api'

export default {
  name: 'Wap-Guidance-Review',
  components: { },
  props: {
    requestData: {
      type: Object,
      default: () => {
      }
    }
  },
  data () {
    return {
      layouts: {
        data: {
          portfolio: {
            iconUrl: ''
          }
        }
      }
    }
  },
  watch: {
    'store.getters.getPortfolioJson': {
      handler: function () {
        this.layouts.data.portfolio.iconUrl = this.$store.getters.getPortfolioJson.iconUrl
      },
      immediate: true,
      deep: true
    }
  },
  mounted () {
    this.getReport()
  },
  methods: {
    getReport () {
      // 在计算之前先查询该账户是否有风险
      OnlineApi.getReport({
        requestId: this.$store.getters.getRequestId
      }, () => {
        this.calculateLoanAmount()
      }, () => {
        this.$router.push('/notification/206')
      })
    },
    calculateLoanAmount () {
      setTimeout(() => {
        OnlineApi.calculateQualifiedAmount((result) => {
          if (result.qualifiedPrincipal > 0) {
            this.$router.push('/disbursement/choose-loan-amount')
          } else {
            if (result?.isWithdrawn) {
              this.$router.push('/notification/301')
            } else if (result?.isNonVoiceAgent) {
              this.$router.push('/notification/202')
            } else {
              this.calculateFailure()
            }
          }
        }, () => {
          this.calculateFailure()
        })
      }, 2000)
    },
    calculateFailure () {
      this.$router.push('/notification/203')
    }
  }
}
</script>

<style lang="sass" scoped>
.review
  height: 100%
  width: 600px
  margin-left: 50%
  -webkit-transform: translateX(-50%)
  display: flex
  flex-direction: column
  background: transparent

  &-title
    font-size: 14px
    font-weight: 500!important
    text-align: center
    padding: 12px

  &-content
    min-height: 400px
    display: flex
    align-items: center
    justify-content: center

  .icon-url
    width: 70px
    height: 70px
</style>
