<template>
    <div
      ref="loanAgreement"
      :style="`height: ${height -6}px;`">
      <iframe
        class="embed-iframe"
        id="signature-iframe"
        :src="layouts.data.loanAgreementUrl"></iframe>
    </div>
</template>

<script>
import { DataType, Sloth } from '@/assets/js/util'

export default {
  name: 'sign-loan-agreement',
  components: {},
  data: () => ({
    height: '0',
    slothUrl: '',
    layouts: {
      data: {
        loanAgreementUrl: ''
      }
    }
  }),
  mounted () {
    this.height = document.body.clientHeight - 44 // 顶部导航高度为44
    this.layouts.data.loanAgreementUrl = this.$store.getters.getDocumentUrl
    this.initWebSocket(this.$store.getters.getLoan.loanNo)
  },
  methods: {
    initWebSocket (loanNo) {
      const $this = this
      let data = {}
      this.judgeEnvironment()
      $this.sloth = new Sloth({
        url: $this.slothUrl,
        mode: 'ClientSide',
        wsid: loanNo
      })
      $this.sloth.init()
      $this.sloth.on('message', function (message) {
        switch (message.data.ticket) {
          case '2060': {
            data = message.data
            if (data.signatureStatus === DataType.SignStatusEnum.SIGNED.value) {
              $this.$router.push('/notification/102')
            }
            break
          }
          default:
            break
        }
      })
    },
    judgeEnvironment () {
      switch (process.env.VUE_APP_TITLE) {
        case 'develop':
          this.slothUrl = 'wsxch-dolphin.sztnb.com'
          break
        case 'production':
          this.slothUrl = 'wsxch-dolphin.sztus.com'
          break
        case 'verification':
          this.slothUrl = 'wsxch-dolphin.sztup.com'
          break
        default :
          break
      }
    }
  }
}
</script>

<style scoped lang="sass">
.embed-iframe
  width: 100%
  height: 100%
  border: none

</style>
