import { ajax } from '@/assets/js/util/ajax'

const BASE_URL = '/dolphin-customer'

const AccountApi = {
  signIn (input, success, failure) {
    return ajax.post(
      `${BASE_URL}/sign-in`,
      input,
      success,
      failure
    )
  },
  sendCreateAccountEmail (input, success, failure) {
    return ajax.post(
      `${BASE_URL}/send-create-account-email`,
      input,
      success,
      failure
    )
  },
  createAccount (input, success, failure) {
    return ajax.post(
      `${BASE_URL}/create-account`,
      input,
      success,
      failure
    )
  },
  parseLinkResource (resource, success, failure) {
    return ajax.get(
      `${BASE_URL}/parse-link-resource`,
      { resource },
      success,
      failure
    )
  },
  sendForgotPasswordEmail (input, success, failure) {
    return ajax.post(
      `${BASE_URL}/send-forgot-password-email`,
      input,
      success,
      failure
    )
  },
  resetPassword (input, success, failure) {
    return ajax.post(
      `${BASE_URL}/reset-password`,
      input,
      success,
      failure
    )
  },
  changePassword (input, success, failure) {
    return ajax.post(
       `${BASE_URL}/change-password`,
       input,
       success,
       failure
    )
  },
  verifyLoginCode (input, success, failure) {
    return ajax.post(
      `${BASE_URL}/verify-login-code`,
      input,
      success,
      failure
    )
  },
  resendVerificationCode (input, success, failure) {
    return ajax.post(
      `${BASE_URL}/resend-verification-code`,
      input,
      success,
      failure
    )
  }
}

export { AccountApi }
